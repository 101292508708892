import { useEffect, useState, useRef } from "react";
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Carousel from 'react-bootstrap/Carousel';
import ReactPixel from 'react-facebook-pixel';
import ImgWithFallback from "../components/ImgWithFallback";
import { useWindowSize } from "../components/WindowSize";
import { BsFillArrowDownCircleFill, BsFillArrowUpCircleFill, BsFillCaretRightFill, BsFillQuestionCircleFill } from 'react-icons/bs';
import { FiPlusSquare, FiMinusSquare } from 'react-icons/fi';
import { MdEmail } from 'react-icons/md';
import { constPRICES, constPREV_PRICES, constPACKAGES, constPACKAGESsubt } from "../components/courseConstants";
import { Link } from "react-router-dom";
import Instructions from "../components/Instructions";
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Modal from 'react-bootstrap/Modal';
import CloseButton from 'react-bootstrap/CloseButton';

const scrollToRef = (ref) => {
    const pos = ref.current.getBoundingClientRect().y - 64;
    window.scrollBy({ top: pos, left: 0, behavior: 'smooth' });
    document.body.click();
}

function MyRow(props) {
    return <Row className="my-row" style={props.style}>
        {props.children}
    </Row>
}

function Circle(props) {
    const src = "./zhy/" + props.color + "ball.";
    return (
        <>
            <div>
                <ImgWithFallback src={src + "webp"} fallbackSrc={src + "png"} className="w100" />
            </div>
            <div className="centeredInImg">
                {props.children}
            </div>
        </>);
}

/*
function ThreeCircles() {
    const st = {color: "black", paddingLeft: "10%", paddingRight: "10%", fontSize: "min(3.0vw, 22px)"};
    return (
        <MyRow style={{textAlign: "center", margin: "auto", maxWidth: "800px"}}>
            <Col style={{position: "relative"}}>
                <Circle color="yellow">
                    <h2 style={st}>Ви творча людина?</h2>
                </Circle>
            </Col>
            <Col style={{position: "relative"}}>
                <Circle color="green">
                    <h2 style={st}>Мрієте навчитися писати лікуючі казки?</h2>
                </Circle>
            </Col>
            <Col style={{position: "relative"}}>
                <Circle color="pink">
                    <h2 style={st}>Хочете допомогти казкою собі та іншим?</h2>
                </Circle>
            </Col>
        </MyRow>
    );
}

function Title() {
    return (
    <>
        <MyRow style={{textAlign: "center", marginTop: "32px"}}>
            <div style={{width: "30%"}}>
                <h1>Курс для батькiв з написання терапевтичних казок</h1>
            </div>
            <div style={{width: "30%"}} />
            <div style={{width: "30%"}}>
                <h1>Виховуємо покоління<br />щасливих дітей!</h1>
            </div>
        </MyRow>
        <MyRow>
            <Col>
                <ImgWithFallback src="./zhy/logo.webp" fallbackSrc="./zhy/logo.png" className="logo" alt="logo" />
            </Col>
        </MyRow>
    </>);
}

function Subtitle() {
    return (
        <MyRow style={{marginTop: "-24px", marginBottom: "16px", textAlign: "center", position: "relative"}}>
            <h1 className="gold bold">Проходьте казковий шлях крок за кроком та втілюйте свою мрію у реальність!</h1>
            <div style={{position: "absolute", width: "32%", top: "-200px", left: "-17%", zIndex: "-99"}}>
                <ImgWithFallback src="./zhy/spots1.webp" fallbackSrc="./zhy/spots1.png" style={{width: "100%"}} />
            </div>    
        </MyRow>
    );
}
*/

function Anna({size}) {
    const sty = size === "big" ? {marginTop: "20%"} : {marginTop: "32px"};
    return (
        <>
        <MyRow style={{padding: "0"}}>
            <Col xs={5} style={{textAlign: "center", display: "flex", alignContent: "center", flexDirection: "column", justifyContent: "center"}}>
                <h3>Засновниця школи, письменниця та поетеса Анна Сапена</h3>
            </Col>
            <Col xs={7} style={{textAlign: "center", position: "relative"}}>
                <div>
                    <div>
                        <ImgWithFallback src="./zhy/balloon-top.webp" fallbackSrc="./zhy/balloon-top.png" className="w100" />
                    </div>
                </div>
                <div className="centeredInImg" style={{paddingLeft: "10%", paddingRight: "10%"}}>
                    <h2 style={{color: "black"}}>Навіщо вам вміти писати терапевтичні казки?</h2>
                </div>
            </Col>
        </MyRow>
        <MyRow style={{padding: "0"}}>
            <Col xs={5} style={{textAlign: "center"}}>
                <ImgWithFallback src="./zhy/anna.webp" fallbackSrc="./zhy/anna.png" alt="Анна Сапена" className="w100" />
            </Col>
            <Col xs={7}>
                <div>
                    <ImgWithFallback src="./zhy/balloon-bottom.webp" fallbackSrc="./zhy/balloon-bottom.png" className="w100" />
                </div>
                <div style={{marginTop: "-30px", textAlign: "center"}}>
                    <ImgWithFallback src="./zhy/bluebird.webp" fallbackSrc="./zhy/bluebird.png" className="w30" />
                    { size === "big" ? <h3 className="bold" style={sty}>Унікальна авторська програма</h3>
                    : <h3 style={{marginTop: "16px"}}>Авторка та дизайнерка курсу, провідна викладачка, казкотерапевтиня Анастасія Юдіна, створила 200+ казок</h3> }
                </div>
            </Col>
        </MyRow>
        </>
    );
}

function Anastasia({width, size}) {
    const div = size === "small" ? 
        (width < 600 ? 22 : (width < 768 ? 26 : 25)) :
        18;
    const cw = Math.floor((width * 8) / 12) / div;
    const fs = cw + "px";
    const sty = size === "big" ? {marginTop: "20%"} : {marginTop: "32px"};
    return (<>
        <MyRow>
            <Col xs={8} style={{textAlign: "center"}}>
                <div style={{position: "relative"}}>
                    <div>
                        <ImgWithFallback src="./zhy/balloon.webp" fallbackSrc="./zhy/balloon.png" className="w100" />
                    </div>
                    <div className="centeredInImg" style={{paddingLeft: "10%", paddingRight: "10%", top: "8px"}}>
                        <p style={{fontSize: fs, lineHeight: "1.1"}}>Щоб ви могли сформувати здорові звички у дитини, позбавити її стресу, скорегувати поведінку, надати впевненості у собі.</p>
                    </div>
                </div>
                { size === "small" ? <h3 className="bold" style={sty}>Унікальна авторська програма</h3>
                    : <h3 style={{marginTop: "16px"}}>Авторка та дизайнерка курсу, провідна викладачка, казкотерапевтиня Анастасія Юдіна</h3> }
            </Col>
            <Col xs={4} style={{position: "relative", textAlign: "center"}}>
                <ImgWithFallback src="./zhy/anastasia.webp" fallbackSrc="./zhy/anastasia.png" alt="Анастасія Юдіна" 
                    className="w100" style={{position: "absolute", left: "-20%", top: "-20%"}} />
            </Col>
        </MyRow>
        </>
    );
}

function Teachers() {
    const [width, ] = useWindowSize();
    if (width < 1000) {
        return (
            <Container style={{marginTop: "32px"}}>
                <MyRow>
                    <Anna size="small"/>
                </MyRow>
                <MyRow style={{marginTop: "32px"}}>
                    <Anastasia width={width} size="small"/>
                </MyRow>
            </Container>
        );    
    }
    return (
        <div style={{marginTop: "32px"}}>
            <MyRow style={{justifyContent: "center"}}>
                <Col>
                    <Anna  size="big"/>
                </Col>
                <Col style={{marginTop: "48px"}}>
                    <Anastasia width={width >> 1} size="big"/>
                </Col>
            </MyRow>
        </div>
    );
}

function ScrollToPay({element, text}) {
    return (
        <Button variant="custom" className="mybutton" onClick={()=>{scrollToRef(element)}} >{text}</Button>
    );
}

function PublicGoal({element}) {
    const [width, ] = useWindowSize();
    const stb = width <= 600 ? {justifyContent: "flex-start", left: "15%", top: "30px", paddingLeft: "12%", 
    paddingRight: "20%", textAlign: "center"}
        : (width < 1200 ? {justifyContent: "flex-start", left: "15%", top: "15%", paddingLeft: "12%", 
        paddingRight: "20%", textAlign: "center"} : {justifyContent: "flex-start", left: "left: min(20%, 100px)", top: "17%", paddingLeft: "12%", 
        paddingRight: "20%", textAlign: "center", alignItems: "flex-start"});
    return (
        <Container style={{backgroundColor: "#f9ece8", marginTop: "32px", borderRadius: "12px", width: "100%"}}>
            <MyRow style={{maxWidth: "100%", width: "100%"}}>
                <Col style={{textAlign: "right"}}>
                    <ImgWithFallback src="./zhy/girl.webp" fallbackSrc="./zhy/girl.png" className="w100"
                        style={{maxWidth: "380px", transform: "translateY(-18px)"}} />
                </Col>
                <Col style={{position: "relative"}}>
                    <div style={{marginTop: "16px"}}>
                        <ImgWithFallback src="./zhy/left-balloon.webp" fallbackSrc="./zhy/left-balloon.png" className="w100" 
                            style={{maxWidth: "420px"}} />
                    </div>
                    <div className="centeredInImg" style={stb}>
                        <h1 style={{fontSize: "min(4vw, 36px)"}}>А для<br />кого курс?</h1>
                    </div>
                </Col>
            </MyRow>
            <MyRow style={{maxWidth: "100%", width: "100%"}}>
                <Col style={{paddingLeft: "10%"}}>
                    <h1>Для батьків<br />Вихователів<br />Психологів</h1>
                </Col>
                <Col style={{textAlign: "center"}}>
                    <ImgWithFallback src="./zhy/greenbird.webp" fallbackSrc="./zhy/greenbird.png" 
                        style={{width: "50%", maxWidth: "128px"}} />
                </Col>
            </MyRow>
            <MyRow style={{maxWidth: "100%", width: "100%"}}>
                <h1 style={{paddingLeft: "10%"}}>Тих, хто працює з дітьми<br />Просто для себе</h1>
                <div style={{textAlign: "center", paddingLeft: "10%", paddingRight: "10%"}}>
                    <h1>А також, для тих, хто пише і хоче пірнути у казку!</h1>
                    <div style={{marginTop: "8px", marginBottom: "16px"}}>
                        <ScrollToPay element={element} text="Записатися на курс!" />
                    </div>
                </div>
            </MyRow>
        </Container>
    );
}

function Timetable({reference}) {
    const [width, ] = useWindowSize();
    const ty = width < 500 ? "-24%" : (width < 700 ? "-21%" : (width < 800 ? "-120px" : "-150px"));
    const lastrowst = "translateY(" + ty + ")";
    return (
    <div style={{position: "relative"}} ref={reference}>
        <Container style={{marginTop: "32px", position: "relative", zIndex: "10"}}>
            <h1 style={{fontWeight: "600", textAlign: "center"}}>ЯК ПРОХОДИТЬ НАВЧАННЯ?</h1>
            <div style={{borderLeft: "solid thick #cf958e", paddingLeft: "10px"}}>
                <h3 style={{marginBottom: "18px"}}>Навчайтеся легко!<br/>
                Всього за <b>15 хвилин в день</b> ви отримаєте найголовнішу та найкориснішу інформацію у цікавому форматі.<br/>
                Ніяких нудних лекцій на багато годин, для яких завжди не вистачає часу!</h3>
                <h2 style={{color: "#4e5b47", fontWeight: "bold"}}>21 день <span style={{fontSize: "small", fontWeight: "normal"}}>
                    (формат для пакетів: Креативна спільнота та Преміум професійний казкотерапевт)</span></h2>
            </div>
        </Container>
        <div style={{position: "absolute", width: "20%", top: "-100px", right: "0%", overflowX: "clip"}}>
            <ImgWithFallback src="./zhy/spots1.webp" fallbackSrc="./zhy/spots1.png" 
                style={{width: "170%", position: "relative", zIndex: "0"}} />
        </div>
        <MyRow style={{maxWidth: "840px", margin: "auto"}}>
            <Col style={{position: "relative", textAlign: "center"}}>
                <Circle color="yellow">
                    <h3><b>Ви будете в:</b></h3>
                    <p className="pincircle">Закритій телеграм групі творчої спільноти</p>
                </Circle>
            </Col>
            <Col style={{position: "relative", textAlign: "center"}}>
                <Circle color="green">
                    <h3><b>Щодня ви отримуватимете:</b></h3>
                    <p className="pincircle">Навчальні пости, корисні аудіо, приклади, схеми, таблиці, вправи, надихаючі підкасти, мотивуючі відео</p>
                </Circle>
            </Col>
            <div style={{position: "absolute", textAlign: "center", transform: "translateY(10px)"}}>
                <ImgWithFallback src="./zhy/roundarrow.webp" fallbackSrc="./zhy/roundarrow.png" 
                    style={{width: "min(18vw, 200px)"}} />
            </div>
        </MyRow>
        <MyRow style={{maxWidth: "840px", margin: "auto"}}>
            <Col style={{position: "relative", textAlign: "center"}}>
                <Circle color="blue">
                    <h3><b>А ще:</b></h3>
                    <p className="pincircle">Тренажери фантазії, реальний кейс казки, працюючі техніки, креативні домашні завдання, ресурсна медитація, мапа казки</p>
                </Circle>
            </Col>
            <Col xs={4}>
                <ImgWithFallback src="./zhy/roundarrow.webp" fallbackSrc="./zhy/roundarrow.png" 
                    style={{width: "min(18vw, 200px)", transform: "translateX(max(-14vw, -90px)) rotate(125deg)"}} />
            </Col>
        </MyRow>
        <MyRow style={{maxWidth: "840px", margin: "auto", transform: "translateY(-50px)"}}>
            <Col style={{textAlign: "right"}}>
                <ImgWithFallback src="./zhy/roundarrow.webp" fallbackSrc="./zhy/roundarrow.png" 
                    style={{position: "absolute", width: "min(18vw, 200px)", 
                        transform: "translate(max(-10vw, -150px), 50px) scaleY(-1) rotate(297deg)", zIndex: "99"}} />
            </Col>
            <Col style={{position: "relative", textAlign: "center"}}>
            <Circle color="pink">
                    <h3 style={{marginLeft: "10%", marginRight: "10%"}}><b>Впродовж курсу у вас буде:</b></h3>
                    <p className="pincircle">Один онлайн вебінар із індивідуальним розбором казки та необмежена підтримка викладачів</p>
                </Circle>
            </Col>
        </MyRow>
        <MyRow style={{transform: lastrowst, margin: "auto", maxWidth: "640px"}}>
            <div>
                <ImgWithFallback src="./zhy/lens.webp" fallbackSrc="./zhy/lens.png" style={{width: "75%"}} />
            </div>
            <div style={{width: "50%", position: "absolute", right: "0px", top: "40%"}}>
                <div>
                    <ImgWithFallback src="./zhy/roundballoon.webp" fallbackSrc="./zhy/roundballoon.png" style={{width: "100%"}} />
                    <div className="centeredInImg">
                        <h2 style={{color: "black", marginLeft: "15%", textAlign: "center"}}>Який формат<br/>курсу?</h2>
                    </div>
                </div>
            </div>
        </MyRow>
    </div>
    );
}

function CourseFormatItem({text, dot, side, width}) {
    const imgSize = Math.min(128, Math.floor(width / 8)) + "px"; 
    if (side === "left") {
        return (<div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center",
            padding: "8px 0px 8px 0px", transform: "translateX(-20px)"}}>
            <img src={"./zhy/bluecircle.png"} alt="" width={imgSize} />
            <FeatureItemText text={text} side={side} />
        </div>);
    } else {
        return (<div style={{display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center",
            padding: "8px 0px 8px 0px", transform: "translateX(20px)"}}>
            <FeatureItemText text={text} side={side} />
            <img src={"./zhy/bluecircle.png"} alt="" width={imgSize} style={{transform: "scaleX(-1)"}} />
        </div>);
    }
}

function FeatureItemText({text, side}) {
    const pos = text.indexOf('(');
    const st = {marginTop: "8px"};
    if (side === "left") st["marginLeft"] = "16px";
    else st["marginRight"] = "16px";
    if (pos < 0) return <h2 style={st}>{text}</h2>;
    return <h2 style={st}>
        {text.substring(0, pos)}
        <span style={{fontSize: "smaller"}}>
        {text.substring(pos)}
        </span>
    </h2>
}

function CourseFormat() {
    const items = ["Теоретична частина у форматі аудіо підкастів, відео, креативних схем, таблиць та прикладів.", 
        "Домашні завдання, що розбудять уяву.",
        "Набір казкових фраз і технік, що підсилюють лікуючий ефект казки і привертають увагу до тексту.",
        "Мотивуючі вправи і постійна підтримка тренера.",
        "Один онлайн вебінар.", 
        "Цікаві літературні прийоми та психологічні техніки.",
        "Розбір і коректура вашої казки.",
        "Дбайливий зворотній зв‘язок від тренерів курсу."];
    const dots = ["1", "2", "1", "3", "1", "3", "1", "3", "1"];
    const [width, ] = useWindowSize();
    if (width < 1000) {
        return (
            <div style={{width: "100%", marginTop: "-10vw", marginLeft: "auto", marginRight: "auto",
                padding: "20px", backgroundColor: "#f9ece8", borderRadius: "12px"}}>
                <div style={{textAlign: "center", paddingTop: "16px"}}>
                    <h1 style={{fontWeight: "600"}}>3 ТИЖНІ - 6 КРОКІВ У КАЗКОВУ ПРИГОДУ</h1>
                </div>
                <div style={{backgroundColor: "white", borderRadius: "12px"}}>
                    {items.map(function(text, index) {
                    return <CourseFormatItem key={index} text={text} dot={dots[index]} side="left" width={width} />
                    })}
                </div>
            </div>
        );
    } else {
        return (<div style={{marginTop: "-54px", backgroundColor: "#f9ece8"}}>
            <div style={{textAlign: "center", paddingTop: "16px"}}>
                <h1 style={{fontWeight: "600"}}>3 ТИЖНІ - 6 КРОКІВ У КАЗКОВУ ПРИГОДУ</h1>
            </div> 
            <MyRow style={{backgroundColor: "#f9ece8", borderRadius: "12px", width: "100%", 
                marginLeft: "auto", marginRight: "auto", padding: "20px"}}>
                <Col style={{backgroundColor: "white", padding: "0px", borderTopLeftRadius: "12px",
                    borderBottomLeftRadius: "12px"}}>
                {items.map(function(text, index) {
                    return index <= 4 ?
                        <CourseFormatItem key={index} text={text} dot={dots[index]} side="left" width={width} /> :
                        null;
                    })}
                </Col>
                <Col style={{backgroundColor: "white", padding: "0px", borderTopRightRadius: "12px",
                    borderBottomRightRadius: "12px", textAlign: "right"}}>
                {items.map(function(text, index) {
                    return index > 4 ?
                        <CourseFormatItem key={index} text={text} dot={dots[index]} side="right" width={width} /> :
                        null;
                    })}
                </Col>
            </MyRow>
            </div>
        );
    }
}

function ProgramItem({item, index, expanded, setExpanded}) {
    return <div style={{boxShadow: "-4px 4px 12px #00000045", padding: "8px",
        marginBottom: "8px", position: "relative"}}>
        <div style={{position: "absolute", right: "10px", top: "6px", fontSize: "26px", cursor: "pointer", color: "#e7bfb3"}}
            onClick={() => {
                if (expanded === index) setExpanded(-1);
                else setExpanded(index);
            }}>
            {expanded === index ? <BsFillArrowUpCircleFill/> : <BsFillArrowDownCircleFill/>}
        </div>
        <h1 style={{fontWeight: "600"}}>{"ПРИГОДА " + index}<br/>{item.title}</h1>
        {expanded === index && <div>
            <ul>
                {item["desc"].map((itemDesc, indexDesc) => <li key={indexDesc}>{itemDesc}</li> )}
            </ul>
            <h4 style={{color: "#007cbf", fontWeight: "600"}}>{item.conclusion}</h4>
        </div>}
    </div>
}

function Program() {
    const [active, setActive] = useState(-1);
    const programs = [
        {title: "Зародження казкового всесвіту", desc: ["Мандруємо до печери первісної людини;",
            "Обираємо шлях і простір казки;", "Знайомимося з видами терапевтичних казок і особливостями їх написання;",
            "Народження головного героя;", "Вчимося робити героя цікавим і близьким до дитини; шукаємо кнопку, що вмикає фантазію;",
            "Прокачуємо м‘яз уяви особливими психологічними вправами."], 
            conclusion: "НАДМОЖЛИВІСТЬ: УТВОРЮВАТИ ВСЕСВІТИ, БАЧИТИ ІДЕЇ В БУДЕННИХ РЕЧАХ."},
        {title: "Сіль цікавого тексту", desc: ["Дізнаємося про скелети у шафі;", 
            "Описуємо проблему казки через різні системи сприйняття світу;",
            "Дізнаємося про секретні інгредієнти цікавого тексту."], 
            conclusion: "НАДМОЖЛИВІСТЬ: ВМІННЯ «ПІДСОЛИТИ» КАЗКУ, ЗНАХОДИТИ СПРАВЖНЮ ПРОБЛЕМУ І ПОТРЕБУ ДИТИНИ."},
        {title: "Екшн", desc: ["Мандрівка дорогою гри Ренка;", "Тренуємо м‘яз уяви через психологічні техніки;", 
            "Спостерігаємо за світом, шукаємо гіперболу у буденних речах;", 
            "Відвідуємо зоопарк фантастичних тварин;", "Тренуємося вигадувати абсурдних героїв і робити із текста екшн;",
            "Отримуємо дієві схеми подолання письменницького ступору;", 
            "Отримуємо таблицю зв'язків сюжету за видами терапевтичних казок."],
            conclusion: "НАДМОЖЛИВІСТЬ: ЗНАХОДИТИСЯ У МИТІ, НАСОЛОДЖУВАТИСЯ ПРОЦЕСОМ, ВІДЧУВАТИ НАТХНЕННЯ."},
        {title: "Зустріч на Ельбі", desc: ["Шукаємо слова казкового мудрого наставника за видами терапевтичних казок;",
            "Пишемо яскраві кульмінаційні діалоги;", "Отримуємо 7 варіантів вирішення будь-якої казкової проблеми, втілюємо їх у життя;",
            "Вчимося метафорично радити наче мастер йода."],
            conclusion: "НАДМОЖЛИВІСТЬ: ВМІННЯ ВИРІШУВАТИ ПРОБЛЕМИ, ДАВАТИ КОРИСНІ ПОРАДИ І ЧУТИ ЇХ."},
        {title: "Практичний казковий досвід", desc: ["Даруємо героям винагороду;", "Отримуємо практичний досвід роботи з казкою;", 
            "Дізнаємося про практичні зайняття з казкотерапії для груп дітей;", 
            "Інсайти практикуючого казкотерапевта;", 
            "Практикуємо терапевтичні техніки: відвідуємо Дім емоцій та Мандруємо лабіринтом ідей;", 
            "Отримуємо казковий план, як правильно скласти лікуючу історію разом з дитиною."],
            conclusion: "НАДМОЖЛИВІСТЬ: ВДАЛО ВИКОРИСТОВУВАТИ НОВІ ЗНАННЯ. БАЧИТИ ВСЕСВІТИ ДИТИНИ."},
        {title: "Голос казки", desc: ["Втілюємо казковий досвід в реальність;", "Вчимося правильно читати терапевтичну казку;", 
            "Отримуємо схему важливих питаннь для казкової терапевтичної розмови;", 
            "Дивимося на пройдений шлях і мапу терапевтичної казки;", 
            "Отримуємо дипломи та збірку з власною казкою (пдф);", 
            "Отримуємо список художньої та терапевтичної літератури;", "Пишаємося своєю мандрівкою!"],
            conclusion: "НАДМОЖЛИВІСТЬ: СПІЛКУВАТИСЯ З ДИТИНОЮ МОВОЮ КАЗКИ, ВИХОВУВАТИ ЩАСЛИВУ ЛЮДИНУ."}          
    ];
    return <Container>
        <Row>
            <Col style={{padding: "11px", display: "flex", justifyContent: "center"}}>
            <img alt="" src="/zhy/arrowdown.png" width="64px" height="89px"/>
            <h1 style={{fontWeight: "600"}}>ПРОГРАМА КУРСУ</h1></Col>
        </Row>
        {programs.map((item, index) =>
            <ProgramItem key={index} item={item} index={index + 1} expanded={active} setExpanded={setActive}/> 
        )}
        <Row style={{height: "16px"}}/>
        </Container>
}

function FeatureItem({text, dot, side, width}) {
    const imgSize = Math.min(128, Math.floor(width / 8)) + "px"; 
    if (side === "left") {
        return (<div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center",
            padding: "8px 0px 8px 0px", transform: "translateX(-20px)"}}>
            <img src={"./zhy/item" + dot + ".png"} alt="" width={imgSize} />
            <FeatureItemText text={text} side={side} />
        </div>);
    } else {
        return (<div style={{display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center",
            padding: "8px 0px 8px 0px", transform: "translateX(20px)"}}>
            <FeatureItemText text={text} side={side} />
            <img src={"./zhy/item" + dot + ".png"} alt="" width={imgSize} style={{transform: "scaleX(-1)"}} />
        </div>);
    }
}

function Features({reference}) {
    const items = ["Власну терапевтичну казку!", 
        "Диплом ел.",
        "Власну книгу (пдф)!",
        "Мапу для написання терапевтичних казок",
        "Творчий депозит",
        "Креативний покроковий гайд із усіма матеріалами курсу (гайд допоможе Вам складати лікуючі казки і надалі)", 
        "Гарний настрій та розвиток фантазії у колі однодумців",
        "Творчій ресурс для зняття емоційної напруги у дитини"];
    const dots = ["1", "2", "1", "3", "1", "3", "1", "3", "1"];
    const [width, ] = useWindowSize();
    const header = <Row ref={reference}>
        <Col style={{padding: "11px", display: "flex", justifyContent: "center"}}>
        <img alt="" src="/zhy/arrowdown.png" width="64px" height="89px"/>
        <h1 style={{fontWeight: "600"}}>ЩО ВИ ОТРИМАЄТЕ?</h1></Col>
    </Row>
    if (width < 1000) {
        return (
            <div style={{width: "100%", marginLeft: "auto", marginRight: "auto",
                padding: "20px", backgroundColor: "#f9ece8", borderRadius: "12px"}}>
                {header}
                <div style={{backgroundColor: "white", borderRadius: "12px"}}>
                    {items.map(function(text, index) {
                    return <FeatureItem key={index} text={text} dot={dots[index]} side="left" width={width} />
                    })}
                </div>
            </div>
        );
    } else {
        return (
            <div style={{backgroundColor: "#f9ece8", borderRadius: "12px", width: "100%", marginLeft: "auto", marginRight: "auto", padding: "20px"}}>
            {header}
            <MyRow>
                <Col style={{backgroundColor: "white", padding: "0px", borderTopLeftRadius: "12px",
                    borderBottomLeftRadius: "12px"}}>
                {items.map(function(text, index) {
                    return index <= 4 ?
                        <FeatureItem key={index} text={text} dot={dots[index]} side="left" width={width} /> :
                        null;
                    })}
                </Col>
                <Col style={{backgroundColor: "white", padding: "0px", borderTopRightRadius: "12px",
                    borderBottomRightRadius: "12px", textAlign: "right"}}>
                {items.map(function(text, index) {
                    return index > 4 ?
                        <FeatureItem key={index} text={text} dot={dots[index]} side="right" width={width} /> :
                        null;
                    })}
                </Col>
            </MyRow>
            </div>
        );
    }
}

function JumpingPic() {
    const [width, ] = useWindowSize();
    const text = width < 768 ? <h4>Я з Вами!<br />Як долучитися?</h4> :
    <h3>Я з Вами!<br />Як долучитися?</h3>;
    return (
        <Container style={{marginTop: "32px"}}>
            <MyRow style={{maxWidth: "860px", margin: "auto"}}>
                <Col style={{position: "relative"}}>
                    <ImgWithFallback src="./zhy/roundballoon2.webp" fallbackSrc="./zhy/roundballoon2.png" style={{width: "100%"}} />
                    <div className="centeredInImg" style={{top: "17%", justifyContent: "flex-start"}}>
                        {text}
                    </div>
                </Col>
                <Col>
                    <ImgWithFallback src="./zhy/jumping.webp" fallbackSrc="./zhy/jumping.png" style={{width: "100%"}} />                    
                </Col>
            </MyRow>
        </Container>
    );
}

function PlanCardItem({text, index}) {
    var dtext, prefix = "", sty = {marginBottom: "0px"};
    if (text === "") {
        dtext = "";
        sty["height"] = "12px";
    } else if (text.startsWith('+')) {
        prefix = "+";
        dtext = " " + text.substring(1);
        sty["color"] = "#0c217b";
    } else if (text.startsWith('-')) {
        prefix = <BsFillCaretRightFill />; //<BsDot />;
        dtext = " " + text.substring(1);
        sty["fontSize"] = "smaller";
    } else {
        prefix = <BsFillCaretRightFill />;
        dtext = " " + text;
    }

    return <p key={index} style={sty}>
        {prefix}{dtext}
        </p>;
}

function PlanCard({data, size}) {
    /*
    var cardSt = {backgroundImage: "url('./zhy/plan-mid.png')", backgroundRepeatX: "no-repeat",
        backgroundRepeatY: "repeat", backgroundSize: "contain", textAlign: "left",
        paddingLeft: "10%", paddingRight: "10%", fontWeight: "bold"};
    if (size === "big") cardSt["minHeight"] = "400px";
    return (
        <div>
            <img src="./zhy/plan-top.png" className="w100" alt=""/>
            <div style={cardSt}>
                {data.map(function(name, index) {
                    return <PlanCardItem key={index} text={name} index={index} />
                  })}
            </div>
            <img src="./zhy/plan-bottom.png" className="w100" alt=""/> 
        </div>
    );*/
    return <div className="planCard">
        {data.map(function(name, index) {
                    return <PlanCardItem key={index} text={name} index={index} />
                  })}
    </div>
}

function PlanPrice({plan, price, prev}) {
    return (<>
        <div style={{position: "relative", margin: "16px", minWidth: "220px", minHeight: "90px",
            display: "flex", alignItems: "center", justifyContent: "center",
            backgroundImage: "url('./zhy/bkgprice.png')", backgroundSize: "contain",
            backgroundRepeat: "no-repeat", backgroundPosition: "center"}}>
            <p className="price">{price + ' грн'}</p>
            <div className="oldprice">
                {prev + ' грн'}
            </div>
        </div>
        <Link to={'/purchase/plan' + plan} style={{textDecoration: "none"}}>
            <Button className="mybutton">Купити курс</Button>
        </Link>    
    </>);
}

export function Plans({element}) {
    const card1 = ["Закрита телеграм група (без чата)", "Повний пакет матеріалів курсу",
        "Креативний покроковий гайд для створення казки", "Диплом ел.",
        "Одноразовий зворотній зв'язок з тренером курсу для модерації казки",
        "Дві ресурсні медитації у подарунок"];
    const card2 = ["-Закрита телеграм група з чатом", "-Повний пакет матеріалів курсу",
        "-Креативний покроковий гайд для створення казки",
        "-Дві ресурсні медитації у подарунок",
        "", "+Один ОНЛАЙН вебінар", "+Перевірка домашнього задання", 
        "+Постійний зворотній зв'язок та підтримка тренерів впродовж курсу (21 день)",
        "+Робота у команді", "+Ел. книга з вашою казкою!", "+Диплом ел. (вказуються перелік опрацьованих технік та години)",
        "+Коректура вашого тексту від професійного редактора", "+Дизайнерське оформлення вашої казки",
        "+Список корисної літератури від тренерів"];
    const card3 = ["-Закрита телеграм група з чатом", "-Повний пакет матеріалів курсу",
        "-Креативний покроковий гайд для створення казки",
        "-Дві ресурсні медитації у подарунок",
        "", "-Один ОНЛАЙН вебінар", "-Перевірка домашнього задання",
        "-Постійний зворотній зв'язок та підтримка тренерів впродовж курсу (21 день)",
        "-Робота у команді", "-Ел. книга з вашою казкою!",
        "-Коректура вашого тексту від професійного редактора", "-Дизайнерське оформлення вашої казки",
        "-Список корисної літератури від тренерів",
        "", "+Консультація з казкотерапевтом, робота з метафоричними картами на пошук ресурсу, натхнення та розвиток фантазії", 
        "+Індивідуальне ведення та окремий чат з казкотерапевтом впродовж курсу", "+Ілюстрація до вашої казки", 
        "+Професійна аудіо озвучка вашої казки від акторів",
        "+Ваша казка з дизайном в окремому ПДФ файлі",
        "+Диплом ел. (вказуються опрацьовані техніки та додаткові години)"];

    const [width, ] = useWindowSize();
    const birds = {maxWidth: "25%", maxHeight: "100px"};
    const header = <Row>
        <Col style={{padding: "11px", display: "flex", justifyContent: "center"}}>
        <img alt="" src="/zhy/arrowdown.png" width="64px" height="89px"/>
        <h1 style={{fontWeight: "600"}}>ОБЕРІТЬ ОДИН З ПАКЕТІВ</h1></Col>
    </Row>

    if (width < 1000) {
        return (<Container ref={element} className="ohidden" style={{backgroundColor: "rgb(249, 236, 232)",
            borderRadius: "12px", padding: "16px 0px 16px 0px"}}>
            {header}
            <MyRow style={{maxWidth: "480px", textAlign: "center", marginLeft: "auto", marginRight: "auto", 
                marginTop: "12px", paddingLeft: "10px", paddingRight: "10px"}}>
                <ImgWithFallback src="./zhy/greenbird.webp" fallbackSrc="./zhy/greenbird.png" style={birds} />
                <h1>{constPACKAGES[0]}</h1>
                {constPACKAGESsubt[0] && <p className="packageSubtitle">{constPACKAGESsubt[0]}</p>}
                <PlanCard data={card1} size="small" />
                <PlanPrice plan="1" price={constPRICES[0]} prev={constPREV_PRICES[0]} />
            </MyRow>
            <MyRow style={{maxWidth: "480px", textAlign: "center", marginLeft: "auto", marginRight: "auto", 
                marginTop: "12px", paddingLeft: "10px", paddingRight: "10px"}}>
                <hr/>
                <ImgWithFallback src="./zhy/orangebird.webp" fallbackSrc="./zhy/orangebird.png" style={birds} />
                <h1>{constPACKAGES[1]}</h1>
                {constPACKAGESsubt[1] && <p className="packageSubtitle">{constPACKAGESsubt[1]}</p>}
                <PlanCard data={card2} size="small" />
                <PlanPrice plan="2" price={constPRICES[1]} prev={constPREV_PRICES[1]} />
            </MyRow>
            <MyRow style={{maxWidth: "480px", textAlign: "center", marginLeft: "auto", marginRight: "auto", 
                marginTop: "12px", paddingLeft: "10px", paddingRight: "10px"}}>
                <hr/>
                <ImgWithFallback src="./zhy/bluebird.webp" fallbackSrc="./zhy/bluebird.png" style={birds} />
                <h1>{constPACKAGES[2]}</h1>
                {constPACKAGESsubt[2] && <p className="packageSubtitle">{constPACKAGESsubt[2]}</p>}
                <PlanCard data={card3} size="small" />
                <PlanPrice plan="3" price={constPRICES[2]} prev={constPREV_PRICES[2]} />
            </MyRow>
        </Container>);
    } else {
        return (
            <div ref={element} style={{textAlign: "center", marginTop: "32px", backgroundColor: "rgb(249, 236, 232)",
                borderRadius: "12px", padding: "16px 0px 16px 0px"}} className="ohidden">
                {header}
                <MyRow style={{alignItems: "flex-end"}}>
                    <Col sm={4}>
                        <ImgWithFallback src="./zhy/greenbird.webp" fallbackSrc="./zhy/greenbird.png" style={birds} />
                    </Col>
                    <Col sm={4}>
                        <ImgWithFallback src="./zhy/orangebird.webp" fallbackSrc="./zhy/orangebird.png" style={birds} />
                    </Col>
                    <Col sm={4}>
                        <ImgWithFallback src="./zhy/bluebird.webp" fallbackSrc="./zhy/bluebird.png" style={birds} />
                    </Col>
                </MyRow>
                <MyRow style={{alignItems: "center"}}>
                    <Col sm={4}><h1>{constPACKAGES[0]}</h1>
                    {constPACKAGESsubt[0] && <p className="packageSubtitle">{constPACKAGESsubt[0]}</p>}
                    </Col>
                    <Col sm={4}><h1>{constPACKAGES[1]}</h1>
                    {constPACKAGESsubt[1] && <p className="packageSubtitle">{constPACKAGESsubt[1]}</p>}
                    </Col>
                    <Col sm={4}><h1>{constPACKAGES[2]}</h1>
                    {constPACKAGESsubt[2] && <p className="packageSubtitle">{constPACKAGESsubt[2]}</p>}
                    </Col>
                </MyRow>
                <MyRow style={{alignItems: "flex-start"}}>
                    <Col sm={4}>
                        <PlanCard data={card1} size="big" />
                        <PlanPrice plan="1" price={constPRICES[0]} prev={constPREV_PRICES[0]} />
                    </Col>
                    <Col sm={4}>
                        <PlanCard data={card2} size="big" />
                        <PlanPrice plan="2" price={constPRICES[1]} prev={constPREV_PRICES[1]} />
                    </Col>
                    <Col sm={4}>
                        <PlanCard data={card3} size="big" />
                        <PlanPrice plan="3" price={constPRICES[2]} prev={constPREV_PRICES[2]} />
                    </Col>
                </MyRow>
            </div>
        );
    }
}

function AddInstructions() {
    const st = {backgroundColor: "#ffe4d4", margin: "18px auto", padding: "4px 8px 4px 8px",
        borderTopRightRadius: "16px", borderBottomRightRadius: "16px", borderLeftStyle: "solid",
        borderColor: "#de8856", borderWidth: "thick"};
    const [width, ] = useWindowSize();
    if (width < 600) {
        st["width"] = "90%";
        st["fontSize"] = "smaller";
    }
    return (<Container className="ohidden" style={st}>
        <p style={{margin: "0px"}}>Усі <b>матеріали</b> будуть <b>збережені</b> та будуть у постійному доступі для вас. Тож ви їх зможете опрацювати у зручний для вас час.</p>
        <p style={{margin: "0px"}}>Пакети: "<b>Креативна спільнота</b>" та "<b>Преміум професійний казкотерапевт</b>" включають один <b>вебінар-зустріч</b>.</p>
        <p style={{margin: "0px"}}>Розклад ви отримаєте після вступу в групу. Вебінари зберігаються та також будуть <b>у постійному доступі!</b></p>
    </Container>);
}

function Project() {
    return (
        <div className="ohidden" style={{backgroundColor: "#f9ece8", borderRadius: "12px", width: "100%", textAlign: "center",
        marginLeft: "auto", marginRight: "auto", marginTop: "32px"}}>
            <MyRow style={{paddingTop: "16px"}}>
                <h1 style={{color: "black"}}>Створіть власну терапевтичну казку!</h1>
                <h2>Навчіться писати, аби ваша дитина сказала:</h2>
            </MyRow>
            <MyRow style={{maxWidth: "680px", alignItems: "flex-start", marginLeft: "auto", paddingBottom: "16px"}}>
                <Col style={{position: "relative", transform: "translateX(20%)"}}>
                    <ImgWithFallback src="./zhy/roundballoon2.webp" fallbackSrc="./zhy/roundballoon2.png" style={{width: "100%"}} />
                    <div className="centeredInImg" style={{justifyContent: "center", paddingLeft: "15%", paddingRight: "10%"}}>
                        <h3>КАЗКИ МОЄЇ МАМИ - НАЙКРАЩІ!</h3>
                    </div>
                </Col>
                <Col>
                    <ImgWithFallback src="./zhy/daughter.webp" fallbackSrc="./zhy/daughter.png" style={{width: "100%"}} />                    
                </Col>
        </MyRow>
    </div>
    );
}

function Community() {
    var comments = [];
    for (var i = 1; i <= 14; i++) {
        comments.push(<Carousel.Item style={{textAlign: "center"}} key={i}>
            <ImgWithFallback src={"./zhy/comment" + i + ".webp"} fallbackSrc={"./zhy/comment" + i + ".png"} className="comment" />
        </Carousel.Item>);
    }
    return (
        <Container className="ohidden" style={{backgroundColor: "#f9ece8", borderRadius: "12px", width: "100%", textAlign: "center",
        marginLeft: "auto", marginRight: "auto", marginTop: "32px", padding: "16px"}}>
            <h1 style={{color: "black", textAlign: "center"}}>Створіть власну терапевтичну казку!</h1>
            {false && <div style={{position: "relative", maxWidth: "620px", margin: "auto"}}>
                <ImgWithFallback src="./zhy/thoughts.webp" fallbackSrc="./zhy/thoughts.png" style={{width: "100%"}} />
                <div className="centeredInImg" style={{width: "35%", justifyContent: "flex-start", textAlign: "center",
                    left: "7%", top: "20%"}}>
                    <h2>А що каже спільнота?</h2>
                </div>
            </div>}
            {false ? <MyRow style={{transform: "translateY(-14px)"}}><h1>Відгуки:</h1></MyRow> :
            <MyRow style={{marginTop: "16px"}}><h1>Відгуки:</h1></MyRow>}
            <Carousel variant="dark">
                {comments}
            </Carousel>            
        </Container>
    );
}

function Banner({ images, speed = 5000, link }) {
    return (
      <a href={link} target="_blank" rel="noreferrer">
      <div className="inner">
        <div className="wrapper">
          <section style={{ "--speed": `${speed}ms` }}>
            {images.map(({ id, image }) => (
              <div className="image" key={id}>
                <img className="bnimg" src={image} alt={id} />
              </div>
            ))}
          </section>
          <section style={{ "--speed": `${speed}ms` }}>
            {images.map(({ id, image }) => (
              <div className="image" key={id}>
                <img className="bnimg" src={image} alt={id} />
              </div>
            ))}
          </section>
          <section style={{ "--speed": `${speed}ms` }}>
            {images.map(({ id, image }) => (
              <div className="image" key={id}>
                <img className="bnimg" src={image} alt={id} />
              </div>
            ))}
          </section>
        </div>
      </div>
      </a>
    );
}

function Trainers({reference}) {
    const [width, ] = useWindowSize();
    const [annaActive, setAnnaActive] = useState(false);
    const [anastasiaActive, setAnastasiaActive] = useState(false);
    const noovieLink = 'https://instagram.com/noovieworld?igshid=MzRlODBiNWFlZA==';
    const anastasiaLink = 'https://instagram.com/nastiny_skazki_magazin?igshid=MzRlODBiNWFlZA==';
    const wcol = 992;
    const imagesAnna = [
        {id: 1, image: "zhy/books/noovie1.png"},
        {id: 2, image: "zhy/books/noovie2.png"},
        {id: 3, image: "zhy/books/winter.png"},
        {id: 4, image: "zhy/books/shasha.png"},
        {id: 5, image: "zhy/books/matus.png"},
        {id: 6, image: "zhy/books/lisi.png"},
        {id: 7, image: "zhy/books/zasinati.png"},
        {id: 8, image: "zhy/books/zelena.png"}
    ];  
    const imagesAnastasia = [
        {id: 1, image: "zhy/books/bitamin.png"},
        {id: 2, image: "zhy/books/bitamin2.jpg"},
        {id: 3, image: "zhy/books/travel.jpg"},
        {id: 4, image: "zhy/books/shasha.png"},
        {id: 5, image: "zhy/books/mami.jpg"},
        {id: 6, image: "zhy/books/bitamin3.jpg"},
        {id: 7, image: "zhy/books/zelena.png"}
    ];
    return <Container style={{textAlign: "center", marginTop: "16px"}} ref={reference}>
        <Row><h1 style={{fontWeight: "600"}}>ВАШІ ТРЕНЕРИ</h1></Row>
        <Row>
            <Col className="colcolumn">
                <h2>АННА САПЕНА</h2>
                <img alt="Анна Сапена" src="zhy/anna.jpg" className="circleImg"/>
                <div style={{position: "absolute", right: "10px", top: "6px", fontSize: "26px", cursor: "pointer", color: "#e7bfb3"}}
                    onClick={() => {
                    if (annaActive) {
                        setAnnaActive(false);
                        if (width >= wcol) setAnastasiaActive(false);
                    }
                    else {
                        setAnnaActive(true);
                        if (width >= wcol) setAnastasiaActive(true);
                    }
                }}>
                    {annaActive ? <BsFillArrowUpCircleFill/> : <BsFillArrowDownCircleFill/>}
                </div>
                {(annaActive || (width >= wcol && anastasiaActive)) &&
                <div style={{textAlign: "justify", fontSize: "14px", lineHeight: "18px", marginTop: "8px", padding: "4px"}}>
                <hr/>
                <p className="psmall">Привіт, мене звати Анна Сапена. Я – дитяча письменниця та поетеса.</p>
                <p className="psmall">Я завжди мріяла створити світ добра та радості, саме тому почала писати дитячі книги, книги, наповнені чарами та нескінченою фантазією.</p>
                <p className="psmall">І, коли я стала мамою, то зрозуміла, що хочу створювати казки, де героєм буде моя дитина. Я почала поглиблено вивчати психологію та відкрила для себе неймовірний ресурс – терапевтичну казку, через яку я лагідно навчаю свого сина розуміти себе, дивитися на світ ширше, знаходити сили і вірні рішення.</p>
                <p className="psmall">Хочу, аби батьки могли створювати власні лікуючі казки. Казкові бесіди зроблять наші сім‘ї міцнішими, дітей самостійнішими, рішення більш виваженими.</p> 
                <p className="psmall">Саме тому я створила ШКОЛУ для БАТЬКІВ з написання терапевтичних казок.</p>
                <p className="psmall">Ця ШКОЛА подарувала сімейну казку вже десяткам родин.</p>
                <p className="psmall">Давайте разом закладати правильний генетичний код через казки  та виховувати покоління щасливих дітей!</p>
                <hr/>
                <p className="psmall"><b>Про мене:</b></p>
                <p className="psmall"><span className="colnum">1.</span> Засновниця ШКОЛИ терапевтичних казок та креативного письменництва.</p>
                <p className="psmall"><span className="colnum">2.</span> Співавторка курсу для батьків: КАЗКИ, ЩО ЛІКУЮТЬ.</p>
                <p className="psmall"><span className="colnum">3.</span> Практикуюча казкиня.</p>
                <p className="psmall"><span className="colnum">4.</span> Засновниця проекту: «СВІТ НУВІ» – створення та видання книг для дітей нового покоління.</p>
                <p className="psmall"><span className="colnum">5.</span> Тренерка дитячого проекту: «МРІЯ+ТИ» зі створення казок.</p>
                <p className="psmall"><span className="colnum">6.</span> Засновниця телеграм каналу: «КАЗКИ, ЩО ЛІКУЮТЬ».</p>
                <p className="psmall"><span className="colnum">7.</span> Авторка казок для Латвійського радіо.</p>
                <p className="psmall"><span className="colnum">8.</span> Засновниця, авторка та модератор освітнього проекту: «ІННОВАЦІЙНА ПЕДАГОГІЧНА МАЙСТЕРНЯ».</p>
                <p className="psmall"><span className="colnum">9.</span> Викладачка.</p>
                <p className="psmall"><span className="colnum">10.</span> Кандидат економічних наук.</p>
                <p className="psmall"><span className="colnum">11.</span> Переможниця міжнародного фестивалю-конкурсу при Міністерстві культури  та інформативної політики України: «УКРАЇНА ОБ’ЄДНУЄ СВІТ», у категорії: Професіонали.</p>
                <p className="psmall"><span className="colnum">12.</span> Завершила тренінги та курси:</p>
                <ul className="ulcolor">
                    <li className="licolor">«Арт практики в кризу» від «Асоціації психології сім’ї».</li>
                    <li className="licolor">Арт-терапія в роботі з собою та іншими.</li>
                    <li className="licolor">Казкотерапія як засіб розвитку творчих здібностей дітей дошкільного віку.</li>
                </ul>
                <hr/>
                <p className="psmall"><b>Мої книги:</b></p>
                <p className="psmall"><span className="colnum">1.</span> Пригоди маленького Нуві. Незнаний Світ.</p>
                <p className="psmall"><span className="colnum">2.</span> Пригоди Нуві та черепашенят. Сніжне Королівство.</p>
                <p className="psmall"><span className="colnum">3.</span> Зимові дива у віршах.</p>
                <p className="psmall"><span className="colnum">4.</span> Казки, що лікують: серія терапевтичних казок.</p>
                <p className="psmall"><span className="colnum">5.</span> Авторка казок в українському дитячому журналі: «Яблунька».</p>
                <p className="psmall"><span className="colnum">6.</span> «ШАША. Дівчинка, що втратила руку та знайшла Всесвіт» (психотерапевтична книга для дітей, що постраждали від війни. Спеціальний проект для лікарні Охматдит).</p>
                <p className="psmall"><span className="colnum">7.</span> ЗЕЛЕНА ВАЛІЗКА (психотерапевтична книга для дітей-переселенців за підтримки проекту "Захистимо дітей України разом")</p>
                </div>
                }
                {width < wcol && <div style={{marginTop: "16px"}}><Banner images={imagesAnna} speed={20000} link={noovieLink}/></div>}
            </Col>
            <Col className="colcolumn">
                <h2>АНАСТАСІЯ ЮДІНА</h2>
                <img alt="Анастасія Юдіна" src="zhy/anastasia.jpg" className="circleImg"/>
                <div style={{position: "absolute", right: "10px", top: "6px", fontSize: "26px", cursor: "pointer", color: "#e7bfb3"}}
                    onClick={() => {
                    if (anastasiaActive) {
                        setAnastasiaActive(false);
                        if (width >= wcol) setAnnaActive(false);
                    } else {
                        setAnastasiaActive(true);
                        if (width >= wcol) setAnnaActive(true);
                    }
                }}>
                    {anastasiaActive ? <BsFillArrowUpCircleFill/> : <BsFillArrowDownCircleFill/>}
                </div>
                {(anastasiaActive || (width >= wcol && annaActive)) &&
                <div style={{textAlign: "justify", fontSize: "14px", lineHeight: "18px", marginTop: "8px", padding: "4px"}}>
                <hr/>
                <p className="psmall">Чому я вирішила вчити батьків писати казки?</p>
                <p className="psmall">Фантазія – це природа людини. Кожен з нас – це неосяжний, глибокий Всесвіт.</p>
                <p className="psmall">Коли я створюю казку з батьками, то мандрую цими Всесвітами. Ця мандрівка надихає і захоплює.</p>
                <p className="psmall">Ми робимо надзвичайні речі: вигадуємо чарівні предмети, знаходимо суперздатності, створюємо особливий світ.</p>
                <p className="psmall">Дивовижно, коли цей світ стає для дитини реальністю.</p>
                <p className="psmall">Мабуть, я намагаюся через казку зберегти віру дитини у дива.</p>
                <hr/>
                <p className="psmall"><b>Про мене:</b></p>
                <p className="psmall"><span className="colnum">1.</span> Практикуюча казкотерапевтиня.</p>
                <p className="psmall"><span className="colnum">2.</span> Авторка та дизайнерка курсу для батьків: КАЗКИ, ЩО ЛІКУЮТЬ.</p>
                <p className="psmall"><span className="colnum">3.</span> Засновниця креативного підходу у казкотерапії.</p>
                <p className="psmall"><span className="colnum">4.</span> Журналістка.</p>
                <p className="psmall"><span className="colnum">5.</span> Авторка двох збірок із терапевтичними казками. Загалом написала понад двісті казок.</p>
                <p className="psmall"><span className="colnum">6.</span> Авторка казок у благодійному проекті для дитячих будинків «МАМИН ГОЛОС».</p>
                <p className="psmall"><span className="colnum">7.</span> Авторка терапевтичних казок у проекті «МОРГАН - КІТ БЛОГЕР».</p>
                <p className="psmall"><span className="colnum">8.</span> Авторка казок для дитячих мобільних додатків та інтерактивних іграшок.</p>
                <p className="psmall"><span className="colnum">9.</span> Засновниця дитячого терапевтичного театру «ТЕАТР ІЗ ОЧИМА».</p>
                <p className="psmall"><span className="colnum">10.</span> Організаторка теапевтичних жіночих зустрічей «ХРАНИТЕЛЬКИ САДУ».</p>
                <p className="psmall"><span className="colnum">11.</span> Слухачка курсу із написання сценарію при Одеськіій кіностудії.</p>
                <p className="psmall"><span className="colnum">12.</span> Випускниця ШКОЛИ письменницької майстерності «РИБА».</p>
                <hr/>
                <p className="psmall"><b>Мої книги:</b></p>
                <p className="psmall"><span className="colnum">1.</span> Збірка терапевтичних казок «Вітаміни без апельсинів».</p>
                <p className="psmall"><span className="colnum">2.</span> Збірка казок для дитячих лікарень «Вітаміни, які потрібно читати».</p>
                <p className="psmall"><span className="colnum">3.</span> Збірка терапевтичних казок для підготовки дитини до першого класу «Вітаміни до школи»</p>
                <p className="psmall"><span className="colnum">4.</span> «ШАША. Дівчинка, що втратила руку та знайшла Всесвіт» (психотерапевтична книга для дітей, що постраждали від війни. Спеціальний проект для лікарні Охматдит).</p>
                <p className="psmall"><span className="colnum">5.</span> Новорічна терапевтична казка «Подорож мікроба в Новий рік».</p>
                <p className="psmall"><span className="colnum">6.</span> Збірка терапевтичних казок для жінок. Казки від емоційного виснаження «Щодо мами…»</p>
                <p className="psmall"><span className="colnum">7.</span> Терапевтичні казки для проекту «Морган – кіт блогер»</p>
                <p className="psmall"><span className="colnum">8.</span> Терапевтичні казки для мобільних додатків.</p>
                <p className="psmall"><span className="colnum">9.</span> ЗЕЛЕНА ВАЛІЗКА (психотерапевтична книга для дітей-переселенців за підтримки проекту "Захистимо дітей України разом")</p>
                </div>
                }
                {width < wcol && <div style={{marginTop: "16px"}}><Banner images={imagesAnastasia} speed={20000} link={anastasiaLink} /></div>}
            </Col>
        </Row>
        {width >= wcol &&
        <Row>
        <Col className="colcolumn">
            <Banner images={imagesAnna} speed={20000} link={noovieLink} />
        </Col>
        <Col className="colcolumn">
            <Banner images={imagesAnastasia} speed={20000} link={anastasiaLink}/>
        </Col>
        </Row>
        }
    </Container>
}

function TeamMembers() {
    const [width, ] = useWindowSize();
    const [active1, setActive1] = useState(false);
    const [active2, setActive2] = useState(false);
    const wcol = 992;

    return <Container style={{marginTop: "16px"}}>
        <Row>
            <Col style={{marginTop: "8px", padding: "8px", position: "relative"}}>
                <h1 style={{fontWeight: "600"}}>Наша казкова команда:</h1>
            </Col>
        </Row>
        <Row>
            <Col className="colcolumn">
                <h1 style={{fontSize: "min(4vw, 26px)", color: "#cdada4"}}>Голос вашої казки</h1>
                <div style={{position: "absolute", right: "10px", top: "6px", fontSize: "26px", cursor: "pointer", color: "#e7bfb3"}}
                    onClick={() => {
                    if (active1) {
                        setActive1(false);
                        if (width >= wcol) setActive2(false);
                    } else {
                        setActive1(true);
                        if (width >= wcol) setActive2(true);
                    }
                    }}>
                    {active1 ? <BsFillArrowUpCircleFill/> : <BsFillArrowDownCircleFill/>}
                </div>
                <div style={{display: "flex", alignItems: "center", flexDirection: "row"}}>
                    <img alt="Світлана Лущ" src="zhy/svitlana.jpg" className="squareImg" style={{marginRight: "16px"}}/>
                    <h2>Світлана Лущ</h2>
                </div>
                {active1 &&
                <div style={{textAlign: "justify", fontSize: "14px", lineHeight: "18px", marginTop: "8px", padding: "4px"}}>
                <hr/>
                <p className="psmall">Режисерка.</p>
                <p className="psmall">Ведуча.</p>
                <p className="psmall">Викладачка акторської майстерності в дитячій театральній студії «РІКА», м. Харків.</p>
                <p className="psmall">Казкотерапевтиня.</p>
                <p className="psmall">Мама двох дітей.</p>
                <div style={{width: "100%", textAlign: "center"}}>
                    <a href="https://instagram.com/lu4svetaaa?igshid=YmMyMTA2M2Y=" target="_blank" rel="noopener noreferrer">
                        <img src="./zhy/instagram.png" alt="" width="36px" />
                    </a>
                </div>
                </div>
                }
            </Col>
            <Col className="colcolumn">
                <h1 style={{fontSize: "min(4vw, 26px)", color: "#cdada4"}}>Казковий редактор ваших казок</h1>
                <div style={{position: "absolute", right: "10px", top: "6px", fontSize: "26px", cursor: "pointer", color: "#e7bfb3"}}
                    onClick={() => {
                    if (active2) {
                        setActive2(false);
                        if (width >= wcol) setActive1(false);
                    } else {
                        setActive2(true);
                        if (width >= wcol) setActive1(true);
                    }
                    }}>
                    {active2 ? <BsFillArrowUpCircleFill/> : <BsFillArrowDownCircleFill/>}
                </div>
                <div style={{display: "flex", alignItems: "center", flexDirection: "row"}}>
                    <img alt="Тетяна Корінь" src="zhy/tatiana.jpg" className="squareImg" style={{marginRight: "16px"}}/>
                    <h2>Тетяна Корінь</h2>
                </div>
                {active2 &&
                <div style={{textAlign: "justify", fontSize: "14px", lineHeight: "18px", marginTop: "8px", padding: "4px"}}>
                <hr/>
                <p className="psmall">Вітаю! Я – ваш казковий редактор, який вміє заварювати чудеса, як м’ятний чай. Смачний напій від поганої погоди, кепського настрою та всіх-всіх негараздів. 
Ставлюся до кожної казки, як до новорічної ялинки. Дістаю іграшки, гірлянди, дощик…І починається. Вміло, в міру, доречно, зі смаком. Редактор не затьмарює, а посилює автора. Ось так!</p>
                <p className="psmall">Я порадник, перший читач, добрий критик, невгамовний веселун, трохи бешкетник. Для кожного автора в мене є торбинка льодяників та ще більша торбинка витримки! Адже кожну історію слід не тільки розпочати, закрутити, мов хурделицю, а й завершити. І кожен автор трі-шеч-ки капризує. Та від цього маю мікстуру натхнення та морс гумору. Сумніви як рукою знімає!</p>
                <hr/>
                <p className="psmall"><b>Про мене:</b></p>
                <p className="psmall">Журналістка зі стажем 20+</p>
                <p className="psmall">Літературний редактор книжок для дітей, підлітків та дорослих.</p>
                <p className="psmall">Дуже люблю спілкуватися та довгі прогулянки з пошуками ідей. А ще – книги, кіно, подорожі та наполегливі тренування. Повага до автора, сила волі, дисципліна та дружба з часом – ось що робить редактора редактором.</p>
                </div>
                }
            </Col>
        </Row>

    </Container>
}

function Partners() {
    /*
                <Col>
                    <a href="http://psy-family.in.ua"><img src="zhy/logopartner.png" height="148px" alt="psy-family"/></a>
                    <a style={{textDecoration: "none"}} href="http://psy-family.in.ua">
                        <h4 style={{fontWeight: "600"}}>ГО «Асоціація психології сім'ї»</h4></a>
                    <p style={{fontSize: "small", marginTop: "-4px"}}>+380952432998</p>
                </Col>
    */
    return <div className="ohidden" style={{backgroundColor: "#f9ece8", 
        width: "100%", textAlign: "center", marginLeft: "auto", marginRight: "auto", marginTop: "32px"}}>
        {false ? <Container fluid>
            <h1 style={{color: "#008ccd", paddingTop: "16px"}}>Наші партнери</h1>
            <Row style={{marginBottom: "24px"}}>
                <Col>
                    <a href="https://www.instagram.com/tvorchist.pro"><img src="zhy/logopartner2.png" height="148px" alt="Творчість"/></a>
                    <a style={{textDecoration: "none"}} href="https://www.instagram.com/tvorchist.pro">
                        <h4 style={{fontWeight: "600", marginTop: "8px"}}>Арт-простір для дітей і дорослих «Творчість.про»</h4></a>
                </Col>
            </Row>
        </Container> : <div style={{height: "24px"}}/>}
    </div>
}

function FaqSubitem({question, answer}) {
    const [expanded, setExpanded] = useState(false);

    return <>
        <p className="faqQuestion" onClick={() => { setExpanded(!expanded); }}>
        <BsFillQuestionCircleFill color="#008ccd" size="14" style={{marginTop: "-4px", marginRight: "10px"}}/>
        {question}{expanded ? <FiMinusSquare style={{marginLeft: "10px"}}/> : <FiPlusSquare style={{marginLeft: "10px"}}/>}</p>
        {expanded && 
        <div className="faqAnswer" dangerouslySetInnerHTML={{__html: answer}} />
        }
    </>
}

function FaqItem({item, value}) {
    const [expanded, setExpanded] = useState(false);
    return <div style={{boxShadow: "-4px 4px 12px #00000045", padding: "8px", borderRadius: "8px", marginBottom: "8px", position: "relative"}}>
        <div style={{position: "absolute", right: "10px", top: "6px", fontSize: "26px", cursor: "pointer", color: "#e7bfb3"}}
            onClick={() => { setExpanded(!expanded);}}>
            {expanded ? <BsFillArrowUpCircleFill/> : <BsFillArrowDownCircleFill/>}
        </div>
        <h2 style={{fontWeight: "600"}}>{item}</h2>
        {expanded && value.map((item, index) =>
            <FaqSubitem key={index} question={item.question} answer={item.answer}/>
        )}
    </div>
}


function Faq({reference}) {
    const q = {'ПРО НАВЧАННЯ': 
        [{question: 'Я навчусь писати терапевтичні казки лише для дітей?',
           answer: 'Ви навчитесь фундаментальним основам казкотерапії та зможете писати терапевтичні казки для людей будь-якого віку: для дітей, підлітків та дорослих. А також, казки для себе.'},
          {question: 'Скільки казок я зможу написати впродовж курсу?',
           answer: 'Впродовж курсу в 21 день, ви писатимете одну казку. Це важливо, аби ви працювали та проживали свою казку, відпрацьовуючи на ній психологічні техніки та літературні прийоми. Після закінчення курсу ви зможете написати терапевтичну казку під будь-який запит.'},
          {question: 'Чи можна пройти курс ще раз, якщо я не встиг разом з потоком (для пакетів: «Креативна спільнота» та «Професійний казкотерапевт»)?',
           answer: 'Якщо ви не впорались з курсом, або не встигли написати свою казку до завершення курсу в 21 день, ви можете долучитися до наступного потоку знову з самого початку за пів вартості курсу. Тренер працює індивідуально з кожним впродовж курсу, тому пройти курс заново безкоштовно не має можливості.– <b>Важливо!</b> Всі матеріали курсу доступні на необмежений строк: ви можете повертатись до них, переглядати знову та поліпшувати результати особисто для себе. Самовдосконалення не має меж!'},
          {question: 'Що робити, якщо я не встигатиму навчатися та писати казку разом із групою курсу?',
           answer: 'Якщо ви обрали пакет: <b>«Сам собі казкар»</b>, ви отримаєте доступ до закритої групи, де зберігаються усі необхідні покрокові матеріали. Ви зможете вивчати їх у доступний для вас час та опрацьовувати самостійно.<br/>Якщо ви обрали пакети: <b>«Креативна спільнота»</b> або <b>«Професійний казкотерапевт»</b>, ви працюватимете у групі. Щодня ви отримуватимете навчальні відео, схеми, таблиці, підкасти та ігри-тренажери уяви.<br/>Ми побудували матеріали так, аби ви навчалися до 15 хвилин на день. Якщо такої змоги в якісь дні не буде, ви завжди зможете надолужити матеріали впродовж курсу у будь-який момент.<br/>Незалежно  від того, на якому етапі ви будете, впродовж курсу ви  зможете надсилати свої виконані завдання та казку тренеру на перевірку та отримаєте швидкий зворотній зв’язок.'},
          {question: 'Чи залишаться запис вебінару та матеріали і як довго буде доступ?',
           answer: 'Усі придбані матеріали залишаються у вашому приватному телеграм каналі без обмежень у часі.'},
          {question: 'Чи зможу я використовувати матеріали курсу?',
           answer: 'Усі матеріали ШКОЛИ – це інтелектуальна власність авторів – Анастасії Юдиної та Анни Сапена. Вони захищені авторським правом. Тож будь-яке використання матеріалів у комерційних цілях заборонено.<br/>Авжеж ви можете використовувати отриману інформацію, візуально відтворивши її на власний розсуд.'},
          {question: 'Якою мовою викладається курс?',
           answer: 'Усі матеріали курсу на українській мові. Проте казку ви можете писати на будь-якій доступній вам мові.'},
          {question: 'Мою казку будуть редагувати?',
           answer: 'Так! У нашій команді працює професійний редактор, який допоможе вам позбутися граматичних помилок. Ваш текст буде бездоганним.'},
          {question: 'Чи можна замовити у вас надруковану збірку казок випускників школи?',
           answer: 'Усі казки випускників Школи оформлюються у спільну збірку казок групи та дизайнерськи оформлюються. Це прекрасна згадка да досвід, яким ви будете пишатися. Збірку ми не друкуємо. Її ви отримаєте в електронному форматі та зможете роздрукувати самостійно.'} 
        ],
        'ПРО ОПЛАТУ КУРСУ':
        [{question: 'Як сплатити за навчання?',
          answer: 'Оберіть пакет навчання. Натисніть на кнопку: <b>«Купити»</b>, заповніть <b>форму із даними</b> та оберіть <b>метод оплати</b>.<br/>Оплата здійснюється карткою Visa або Mastercard будь-якого банку світу.<br/>Також ви можете обрати спосіб оплати: переказ коштів за реквізитами.<br/>Якщо у вас виникнуть проблеми з оплатою, напишіть нам (контакти внизу на сайті).'},
         {question: 'Маю технічні проблеми з оплатою курсу.',
          answer: 'Якщо у вас виникли проблеми при спробі оплатити курс, напишіть нам: Анна Сапена або Анастасія Юдіна. Часто банк не пропускає оплату через налаштування та обмежені інтернет-платежі. Ми запропонуємо вам інші методи оплати.'}
        ],
        'ПРО ДИПЛОМИ ТА КВАЛІФІКАЦІЮ':
        [{question: 'Якщо у мене немає психологічної освіти, чи можу я практикувати після вашого навчання?',
          answer: 'Ви освоїте казкотерапію, як арт-терапевтичний інструмент незалежно від попередньої освіти. Ви зможете проводити індивідуальні та групові заняття з арт-терапії. <b>Важливо!</b> Психологом ви не станете: таку освіту можна здобути лише у ВНЗ.'},
         {question: 'Я психолог, чи буде корисним мені цей курс?',
          answer: 'Так. Цей курс навчить вас багатьом практичним методам роботи з дітьми та дорослими, надасть реальні працюючі інструменти, які ви зможете впровадити у свою практику.'},
         {question: 'Чи вважається цей курс, як проходження підвищення кваліфікації?',
          answer: 'По успішному завершенню курсу, ви отримаєте диплом із підписами тренерів. Вказування годин та кредитів ЄКТС на дипломі не передбачено.'},
         {question: 'Чи відрізняються дипломи у різних пакетах?',
          answer: 'Так. Якщо ви обрали пакет: <b>«Сам собі казкар»</b>, після перевірки вашої казки тренером, ви отримаєте диплом із вказанням успішного проходження курсу та написанням терапевтичної казки.<br/>Якщо ви обрали пакети: <b>«Креативна спільнота»</b> або <b>«Професійний казкотерапевт»</b>, ви отримаєте диплом після надання завершеної казки тренеру впродовж курсу, тобто впродовж 21-го дня. На дипломі додатково буде вказана кількість годин навчання, перелік тем та прийомів, якими ви оволоділи.<br/>У пакеті <b>«Професійний казкотерапевт»</b> вказується додаткова кількість годин.'},
         {question: 'Я точно отримаю диплом?',
          answer: 'Якщо ви напишете власну казку – так. В дипломі вказується, що ви успішно завершили курс та написали терапевтичну казку. Готова казка – це ваша курсова, фінальна робота, яка є підтвердженням вашого навчання.'},
         {question: 'Чи можливо замовити у вас паперовий диплом?',
          answer: 'Після успішного завершення курсу, ви отримаєте електронний диплом, який за потреби можете надрукувати самостійно.'}
        ]
        }
    return <Container ref={reference} style={{marginTop: "8px"}}>
        <Row>
            <Col style={{padding: "11px", display: "flex", justifyContent: "center"}}>
            <h1 style={{fontWeight: "600"}}>ВАШІ ЗАПИТАННЯ</h1></Col>
        </Row>
        {Object.keys(q).map((item, index) =>
            <FaqItem key={index} item={item} value={q[item]}/> 
        )}
        <Row style={{height: "16px"}}/>
    </Container>
}

export function Contact() {
    const iconst = {maxWidth: "40%", marginTop: "12px", marginBottom: "12px"};
    const lasticonst = {maxWidth: "40%", marginBottom: "12px"};
    return (
    <div className="ohidden" style={{backgroundColor: "#f9ece8", borderTopLeftRadius: "12px", borderTopRightRadius: "12px", width: "100%", textAlign: "center",
        marginLeft: "auto", marginRight: "auto", marginTop: "32px"}}>
        <Container fluid>
            <h1 style={{color: "#008ccd"}}>Є ще запитання?</h1>
            <h2 style={{color: "black", marginBottom: "32px"}}>Напишіть нам та ми розкажемо детальніше про курс, тарифи та нашу спільноту</h2>
            <MyRow style={{backgroundColor: "white", margin: "8px", borderRadius: "12px", borderColor: "#fed065",
                borderStyle: "outset"}}>
                <div style={{width: "fit-content", backgroundColor: "#fed065", padding: "8px 14px 8px 14px",
                    borderRadius: "24px", transform: "translateY(-18px)"}}>
                    <h4 style={{color: "black"}}>Долучайтеся до нас 👇</h4>
                </div>
                <Row>
                    <Col style={{position: "relative"}}>
                        <div style={{maxWidth: "200px", margin: "auto"}}>
                            <Circle color="yellow">
                                <h4 style={{fontWeight: "bold", marginLeft: "25%", marginRight: "25%"}}>Інстаграм спільнота</h4>
                            </Circle>
                        </div>
                    </Col>
                    <Col style={{position: "relative"}}>
                        <div style={{maxWidth: "200px", margin: "auto"}}>
                            <Circle color="green">
                                <h4 style={{fontWeight: "bold", marginLeft: "25%", marginRight: "25%"}}>Канал терапевтичних казок</h4>
                            </Circle>
                        </div>
                    </Col>
                    <Col style={{position: "relative"}}>
                        <div style={{maxWidth: "200px", margin: "auto"}}>
                            <Circle color="pink">
                                <h4 style={{fontWeight: "bold", marginLeft: "25%", marginRight: "25%"}}>Сторінки тренерів</h4>
                            </Circle>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col style={{position: "relative"}}>
                        <div style={{maxWidth: "200px", margin: "auto"}}>
                            <a href="https://instagram.com/zhyly.buly" target="_blank" rel="noopener noreferrer">
                                <img src="./zhy/instagram.png" alt="" width="49px" style={iconst} />
                            </a>
                        </div>
                    </Col>
                    <Col style={{position: "relative"}}>
                        <div style={{maxWidth: "200px", margin: "auto"}}>
                            <a href="https://t.me/Kazki_AnnaSapena" target="_blank" rel="noopener noreferrer">
                                <img src="./zhy/telegram.png" alt="" width="49px" style={iconst} />
                            </a>
                        </div>
                    </Col>
                    <Col style={{position: "relative", display: "flex", alignItems: "center"}}>
                        <div style={{width: "50%"}}>
                            <p style={{margin: "0px", fontSize: "max(1.25vw, 9px)"}}>Анна</p>
                            <a href="https://www.instagram.com/anna_sapena" target="_blank" rel="noopener noreferrer">
                                <img src="./zhy/instagram.png" alt="" width="49px" style={lasticonst} />
                            </a>
                        </div>
                        <div style={{width: "50%"}}>
                            <p style={{margin: "0px", fontSize: "max(1.25vw, 9px)"}}>Анастасія</p> 
                            <a href="https://instagram.com/nastiny_skazki" target="_blank" rel="noopener noreferrer">
                                <img src="./zhy/instagram.png" alt="" width="49px" style={lasticonst} />
                            </a>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <p style={{fontSize: "smaller"}}><MdEmail size="22px" style={{marginTop: "-4px", marginRight: "2px"}}/>
                    Контактна адреса електронної пошти: <a href="mailto:support@magistrika.com">support@magistrika.com</a></p>
                </Row>
            </MyRow>
            <div style={{height: "8px"}}></div>
        </Container>
    </div>
    );
}

function MenuPane({ref1, ref2, ref3, ref4, ref5, stick}) {
    const [expanded, setExpanded] = useState(false);
    const scrollAndCloseMenu = (ref) => {
        setExpanded(false);
        scrollToRef(ref);
    }
    const cname = stick ? "animateFadeIn" : "nonAnimateFadeIn";
    return <div className={cname}>
        <Navbar expand="lg" expanded={expanded}>
            <Container fluid>
                <Navbar.Brand><img width="36px" height="36px" src="logo192.png" alt="Magistrika"/></Navbar.Brand>
                <Navbar.Toggle onClick={() => setExpanded(expanded ? false : "expanded")} />
                <Navbar.Collapse className="justify-content-end">
                    <Nav className="me-auto">
                        <Nav.Link onClick={()=>{scrollAndCloseMenu(ref1)}}>Формат курсу</Nav.Link>
                        <Nav.Link onClick={()=>{scrollAndCloseMenu(ref2)}}>Що я отримаю?</Nav.Link>
                        <Nav.Link onClick={()=>{scrollAndCloseMenu(ref3)}}>Тренери</Nav.Link>
                        <Nav.Link onClick={()=>{scrollAndCloseMenu(ref5)}}>Запитання</Nav.Link>
                    </Nav>
                    <Nav className="me-auto">
                        {expanded 
                        ? <Nav.Link onClick={()=>{scrollAndCloseMenu(ref4)}}>Записатися</Nav.Link>
                        : <Nav.Link onClick={()=>{scrollAndCloseMenu(ref4)}} className="myNavLinkButton">Записатися</Nav.Link>
                        }
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
        </div>
}

function Menu({ref1, ref2, ref3, ref4, ref5}) {
    const [stick, setStick] = useState(false);

    const listenToScroll = () => {
        const hToHide = window.innerHeight;
        const h = document.body.scrollTop || document.documentElement.scrollTop;
        setStick(h > hToHide); 
    }

    useEffect(() => {
        window.addEventListener("scroll", listenToScroll);
        return () => window.removeEventListener("scroll",  listenToScroll);
    }, []);
    
    return <MenuPane ref1={ref1} ref2={ref2} ref3={ref3} ref4={ref4} ref5={ref5} stick={stick}/>
}

function NewTitle({reference}) {
    const [show, setShow] = useState(false);
    return <>
    <div style={{marginTop: "16px", textAlign: "center", width: "100%", display: "flex", overflowX: "hidden",
        justifyContent: "center"}}>
        <div style={{width: "30%", maxWidth: "200px"}}>
            <ImgWithFallback src="./zhy/logo.webp" fallbackSrc="./zhy/logo.png" className="logo" alt="logo" />
        </div>
        <div style={{width: "70%", maxWidth: "940px", overflowX: "hidden"}}>
            <h1 style={{color: "black"}}>ПРАКТИЧНА КАЗКОТЕРАПІЯ</h1>
            <h2>ВИХОВУЄМО ПОКОЛІННЯ ЩАСЛИВИХ ДІТЕЙ!</h2>
            <div style={{width: "100%", height: "4em", background: "url('zhy/blueline.png')", backgroundRepeat: "no-repeat",
                backgroundSize: "90% 100%", backgroundPosition: "center", display: "flex", justifyContent: "center",
                alignItems: "center", marginTop: "16px", overflowX: "hidden"}}>
                <p className="lightTitle">СТАРТ КУРСУ 9 ВЕРЕСНЯ 2024 РОКУ</p>
            </div>
        </div>
    </div>
    <div style={{width: "100%", display: "flex", overflowX: "clip", justifyContent: "center"}}>
        <div style={{width: "50%", padding: "7%", display: "flex", position: "relative", 
            justifyContent: "center", alignItems: "center", maxWidth: "600px"}}>
            <img src="zhy/hand.png" alt="" style={{position: "absolute", width: "135%", height: "140%", 
                zIndex: "-99", top: "-15%"}}/>
            <p className="lightTitle" style={{textAlign: "center", maxWidth: "380px"}}>
                СТВОРІТЬ ВЛАСНУ ЛІКУЮЧУ КАЗКУ І ВИРІШІТЬ ПРОБЛЕМИ, ЯКІ ДАВНО ТУРБУЮТЬ</p>
        </div>
        <div style={{width: "50%", maxWidth: "600px", paddingTop: "16px", paddingLeft: "10%"}}>
            <p className="darktitle" style={{transform: "translateX(-32px)"}}>
                <img src="zhy/pen.png" alt="pencil" width="10%" style={{maxWidth: "48px"}}/>21 ДЕНЬ У КАЗКОВУ ПРИГОДУ</p>
            <p className="darktitle" style={{transform: "translateX(-6px)"}}>
                <img src="zhy/clock.png" alt="clock" width="12%" style={{maxWidth: "56px", marginRight: "8px"}}/>15 ХВИЛИН В ДЕНЬ</p>
            <p className="darktitle">
                <img src="zhy/books.png" alt="books" width="12%" style={{maxWidth: "70px", marginRight: "8px"}}/>КАЗКА, ДИПЛОМ, ЗБІРКА, 
ПОКРОКОВИЙ ГАЙД</p>
        </div>
    </div>
    <div style={{display: "flex", alignItems: "center", justifyContent: "space-evenly", width: "100%"}}>
        <img onClick={() => {setShow(true);}} className="playButton animatePulse" src="zhy/play.png" alt="play"/>
        <Button className="mybutton" onClick={() => {scrollToRef(reference);}}>ЗАПИСАТИСЯ</Button>
    </div>
    <Modal show={show} onHide={() => {setShow(false);}} fullscreen={true}>
        <div style={{width: "100%", height: "100%", backgroundColor: "black", position: "relative", padding: "16px"}}>
            <div style={{textAlign: "right", paddingRight: "20px", paddingBottom: "8px"}}>
                <CloseButton variant="white" onClick={() => {setShow(false);}}/>
            </div>
            <div style={{paddingLeft: "16px", paddingRight: "16px", height: "94%"}}>
                <video width="100%" height="100%" controls autoPlay>
                    <source src="zhy/video/presentation.mp4" type="video/mp4"/>
                    Your browser does not support the video tag.
                </video>
            </div>
        </div>
    </Modal>
    <div style={{display: "flex", justifyContent: "center"}}><hr style={{width: "90%"}}/></div>
    </>
}

function Zhylybuly({title}) {
    useEffect(() => {
        document.title = title;
        ReactPixel.init("206064184014090");
        ReactPixel.pageView();
    }, [title]);
    const payRef = useRef(null);
    const formatRef = useRef(null);
    const programRef = useRef(null);
    const trainersRef = useRef(null);
    const faqRef = useRef(null);
    return (
        <>
            <div className="headerDiv">
                <h2 style={{margin: "0px", color: "rgb(245, 235, 194)"}}>ШКОЛА ТЕРАПЕВТИЧНИХ КАЗОК ТА КРЕАТИВНОГО ПИСЬМЕННИЦТВА</h2>
            </div>
            <Menu ref1={formatRef} ref2={programRef} ref3={trainersRef} ref4={payRef} ref5={faqRef}/>
            <NewTitle reference={payRef} />
            <Teachers />
            <Container style={{textAlign: "center", marginTop: "32px"}}><ScrollToPay element={payRef} text="Беру курс!" /></Container>
            <PublicGoal element={payRef} />
            <Timetable reference={formatRef}/>
            <CourseFormat />
            <Program />
            <Features reference={programRef} />
            {false ? <JumpingPic /> : <div style={{height: "16px"}}/>}
            <Plans element={payRef} />
            <Instructions />
            <AddInstructions />
            {false && <Project />}
            <Community />
            <Trainers reference={trainersRef} />
            {false && <TeamMembers />}
            <Partners/>
            <Faq reference={faqRef}/>
            <Contact />
            <div style={{backgroundColor: "white", textAlign: "center", fontSize: "x-small", borderTop: "inset thin #f9c2b1"}}>
                <div style={{display: "flex", justifyContent: "space-evenly", flexWrap: "wrap", marginTop: "4px"}}>
                    <Link className="mylink" to="/terms/about">ПРО НАС</Link>
                    <Link className="mylink" to="/terms/offer">ОФЕРТА</Link>
                    <Link className="mylink" to="/terms/payment">ОПЛАТА</Link>
                    <Link className="mylink" to="/terms/privacy">ПОЛІТИКА КОНФІДЕНЦІЙНОСТІ</Link>
                    <Link className="mylink" to="/contact">КОНТАКТИ</Link>
                </div>
                <div style={{textAlign: "left"}}>
                    <img src="/terms/visa.svg" alt="visa" width={52} style={{marginRight: "6px"}}/>
                    <img src="/terms/mastercard.svg" alt="mastercard" width={36}  style={{marginRight: "6px"}}/>
                    <Link className="mylink" to="/terms/payment">Більше інформації</Link>
                </div>
                <p style={{margin: "0px", paddingBottom: "8px"}}>ШКОЛА ТЕРАПЕВТИЧНИХ КАЗОК ТА КРЕАТИВНОГО ПИСЬМЕННИЦТВА © 2022-2023.</p>
            </div>
        </>);
}

export default Zhylybuly;